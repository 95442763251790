@vortexStaticResources: '/vrtx/__vrtx/static-resources/themes/default/images/';
@imagePath: '/vrtx/dist/resources/uio2/css/images/';
@fontPath: '/vrtx/dist/resources/uio2/css/fonts/';

// Possible to overwrite on other hosts
:root {
  --textColor: #000;
  --textColorUnderline: currentColor;
  --linkColor: #2143ef;
  --linkColorUnderline: currentColor;
  --borderColor: #000;
  --publishedDateGrey: #3b3b3b;

  --btnPrimaryBg: #18191c;
  --btnPrimaryBgHover: rgba(24, 25, 28, 0.7);
  --btnPrimaryColor: #fff;
  --btnPrimaryColorHover: #fff;

  --btnSecondaryBg: #fff;
  --btnSecondaryBgHover: rgba(0, 0, 0, 0.11);

  --btnSecondaryColor: #000;
  --btnSecondaryColorHover: #000;

  --infoBoxDefaultColor: #18191c;

  --mainFontStack: Helvetica, Arial, sans-serif;
  --secondaryFontStack: "Times New Roman", Georgia, serif;

  --alternateRowColor: #f4f6ff;
  --width: 100vw; // uses JS for more precision as vw ignores vertical scrollbar
}

@textColor: var(--textColor);
@textColorUnderline: var(--textColorUnderline);
@linkColor: var(--linkColor);
@linkColorUnderline: var(--linkColorUnderline);

@borderColor: var(--borderColor);
@publishedDateGrey: var(--publishedDateGrey);

@btnPrimaryBg: var(--btnPrimaryBg);
@btnPrimaryBgHover: var(--btnPrimaryBgHover);
@btnPrimaryColor: var(--btnPrimaryColor);
@btnPrimaryColorHover: var(--btnPrimaryColorHover);

@btnSecondaryBg: var(--btnSecondaryBg);
@btnSecondaryBgHover: var(--btnSecondaryBgHover);
@btnSecondaryColor: var(--btnSecondaryColor);
@btnSecondaryColorHover: var(--btnSecondaryColorHover);

@infoBoxDefaultColor: var(--infoBoxDefaultColor);

@mainFontStack: var(--mainFontStack);
@secondaryFontStack: var(--secondaryFontStack);

// Color palette (not much in use yet)
@redDark: #F40301;
@red: #EE2E25;
@redLight: #FC6666;
@redLightest: #FACED5;

@blue: #2D65E2;
@blueLight: #86A4F7;
@blueLightest: #D7E1F9;

@blueViolet: #2143EF;
@violet: #3E31D6;

@orange: #fea11b;
@orangeLight: #FBCC86;
@orangeLightest: #FFE9C8;
@yellow: #FFFEA7;

@green: #2EC483;
@greenLight: #1CD761;
@greenLightest: #CAEADD;
@greenDark: #07614F;

// Grid widths
@gridWidth: 1440px;
@gridMinWidth: 1050px;
@sidebarWidth: 220px;
@contentGapLeft: 25px;
@contentWidth: 1200px;
@contentMinWidth: 830px;
@contentGapRight: 20px;

@h1IntroWidth: 1000px;
@bodytextContentWidth: 690px;

// Frontpage
@boxGreyBg: var(--alternateRowColor);
@boxLightGreyBg: #e2e3e5;
@boxDarkGreyBg: #444;
@UiORed: #d00002;
@boxPinkOrange: #fb6666;
@boxDarkGrey: #b2b3b7;
@boxBlue: #86a4f7;

@verticalGap: 80px;

@listSuccessChar: "\2714";
@listSuccessColor: #008000;

@tableZebraGreyBg: #eceff8;
@responsiveBreakpointPx: 768px;
@desktopSmallBreakpointPx: 1070px;

@responsiveBreakpoint: ~"only screen and (max-width: @{responsiveBreakpointPx})";
@desktopSmallOnlyBreakpoint: ~"only screen and (min-width: 769px) and (max-width: @{desktopSmallBreakpointPx})";
@desktopSmallBreakpoint: ~"only screen and (max-width: @{desktopSmallBreakpointPx})";
@desktopBreakpoint: ~"only screen and (min-width: 1071px)";
