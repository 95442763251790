@import '../../../../../../lib/uio2/css/base/base.less';
@import '../../../../../../lib/uio2/css/typography.less';
@import '../../../../../../lib/uio2/css/grid.less';
@import '../../../../../../lib/uio2/css/components.less';
@import '../../../../../../lib/uio2/css/documents.less';
@import '../../../../../../lib/uio2/css/listings.less';
@import '../../../../../../lib/uio2/css/search.less';

@responsiveConsentBreakpoint: ~"only screen and (max-width: 768px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";
@import '../../../../../groups/all/src/resources/css/consent.less';

@import '../../../../../../lib/uio2/css/frontpage-edit.less';
@import '../../../../../../lib/uio2/css/embedded.less';
@import '../../../../../../lib/uio2/css/print.less';

@import 'base/variables.less';
@import '../../../../../groups/uio-museum/src/resources/css/common.less';

@globalBg: #FBFFF2;
@footerBg: #18191C;
@socialMediaRow: #ADB797;
@buttonInfoBox: #312D28;
@tilesGlobalMenuBg: #FFEADD;
@exhibitionInfoBg: #EADDFE;
@exhibitionCategoryBg: #312D28;
@boxRowCustomGreyBg: #E3E3E3;

html,
.sidebar-menu-wrapper,
#left-menu-same-level-folders,
#main .grid-container:not(.row-all-colored),
#main .row-all-colored:not(.grid-color-yellow):not(.grid-color-black):not(.grid-color-grey) {
  background: @globalBg;
}

.main {
  .vrtx-frontpage-box a.button,
  .vrtx-frontpage-box .button,
  .vrtx-frontpage-box a.button-large,
  .vrtx-frontpage-box .button-large,
  .button, .button-large, a.button, a.button-large, .button-large-blue, .ui-dialog.ui-widget button, .vrtx-button-larger:not(form), .vrtx-button:not(form), button, input[type=button], input[type=reset], input[type=submit] {
    max-width: none;
    width: auto;

    &:not(.feedback-yes):not(.feedback-no) {
      &:hover,
      &:focus {
        padding-left: 20px;
      }
    }
  }
  .vrtx-frontpage-box a.button, .vrtx-frontpage-box .button, a.button, .button {
    &:not(.feedback-yes):not(.feedback-no) {
      &:hover,
      &:focus {
        padding-left: 19px;
      }
    }
  }
}

#head-wrapper {
  min-height: 160px;
}

.vrtx-facts-container:not(.exhibition-info),
#header-lang #header-lang-tooltip {
  background-color: @buttonInfoBox;
}

.vrtx-facts-container:not(.exhibition-info) {
  *,
  a {
    color: #fff;
  }
  a {
    text-decoration-color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

#footer-wrapper {
  background: @footerBg;
}

// Gallery
.main .vrtx-image-listing-include .vrtx-image-listing-loading-bg {
  background: #FBFFF2;
}

#logo {
  background: url("../images/logo.svg") no-repeat 0 0;
  background-size: auto 100%;
  display: block;
  width: 265px;
  height: 70px;
  float: left;
}

body.en #logo {
  background-image: url("../images/logo-en.svg");
  width: 282px;
}

// Media wide
body.has-page-top-row-white {
  #logo {
    background-image: url("../images/logo-white.svg");
  }
  &.en {
    #logo {
      background-image: url("../images/logo-en-white.svg");
    }
  }
}

// Media embed NHM custom color
body.has-page-top-row-white,
body.has-page-top-row {
  &:not(.sidebar-menu-wrapper-visible) {
    .sidebar-menu-toggle,
    #head-wrapper #head #header-language {
      background-color:rgba(236,253,192, 0.9); // #ecfdc0
      transition: background linear 0.5s;
    }
    #header-items {
      .button {
        background-color:rgba(236,253,192, 0.9);
        transition: background linear 0.5s;
        border: none;
  
        &:hover,
        &:focus {
          background-color:rgba(236,253,192, 0.9);
        }
      }
    }
  }
  &.sidebar-menu-wrapper-visible {
    .sidebar-menu-toggle,
    #head-wrapper #head #header-language {
      background-color:rgba(236,253,192, 0); // #ecfdc0
    }
    #header-items {
      .button {
        background-color:rgba(236,253,192, 0);
      }
    }
  }
}

.grid-container.fullwidth-image .vrtx-box-content,
.grid-container.fullwidth-video .text-box {
  background-color: rgba(236,253,192, 0.9);
}

#main .row-all-colored.grid-color-grey,
#main .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line).row-all-colored.grid-color-grey {
  background: @boxRowCustomGreyBg;
}

.vrtx-frontpage-box.sub-header-box-big.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture:after,
.vrtx-frontpage-box.sub-header-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture:after {
  display: none;
}

// Frontpage box carousel
#vrtx-frontpage #main #vrtx-main-content .frontpage-img-carousel {
  position: relative;

  .grid-container {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;

    &:not(.frontpage-img-carousel-visible) {
      opacity: 0;
      transition: opacity 1100ms ease-in-out;
    }
    &.frontpage-img-carousel-visible {
      opacity: 1;
      transition: opacity 1100ms ease-in-out;
    }
  }
}

// Exhibition tags

.exhibition .vrtx-tags {
  display: none;
}

main .categories {
  margin-top: 10px;

  dd {
    // use !important here as an exception (in small controlled enviroment,
    //                                      categories will only be used for this on historisk museum)
    padding: 2px 10px 2px 10px !important;
    margin: 0 10px 12px 0 !important;
    width: auto !important;
    float: left !important;
    clear: both !important;

    display: block;
    background: @exhibitionCategoryBg;
    color: #fff;
    letter-spacing: 0.2px;
    &:after {
      content: "";
      width: 100%;
      display: block;
    }
  }
}

// Exhibition

#main .exhibition-info {
  padding: 40px 40px 50px 40px;
  margin: 40px 0 80px 0;
  background: @exhibitionInfoBg;
  max-width: 1000px;

  >*:first-child {
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin-bottom: 10px;
  }
  >*:last-child {
    margin-bottom: 0;
  }
  p {
    font-size: 2.3rem;
    line-height: 3.2rem;
  }
}

#main .opening-hours-botanic,
#main .opening-hours-exhibitions {
  .only-links {
    display: none;

    &:last-child {
      display: block;
    }
  }
  .table-wrapper-responsive {
    table {
      margin-top: 0;
    }
  }
}

.vrtx-frontpage-box.img-special-left,
.vrtx-frontpage-box.img-special-right {
  &:not(.transparent-box) {
    background: @buttonInfoBox;
  }
}

.button-large, .button-large-blue, .vrtx-button-larger:not(form) {
  background: @buttonInfoBox;
}

// Opened global menu
.sidebar-menu-wrapper-visible {
  .sidebar-menu,
  #head-wrapper {
    background: @tilesGlobalMenuBg;
  }
}

#main .grey-box #vrtx-semester-links a, #main .grey-box .navigation-links a, // Cause automatic is turned off
#main #vrtx-semester-links li a, #main .navigation-links ul li a, #main ul.navigation-links li a {
  background: @tilesGlobalMenuBg;

  &:hover,
  &:focus {
    background: darken(@tilesGlobalMenuBg, 5%);
  }
}

#main .social-media-container.row-all-colored {
  background: @socialMediaRow;
}

#left-menu-same-level-folders li a.vrtx-marked {
  background: @tilesGlobalMenuBg;
}

#main #right-main div.fourths-left,
#main #right-main div.fourths-middle,
#main #right-main div.left,
#main #right-main div.thirds-left,
#main #right-main div.thirds-middle,
#main #total-main div.fourths-left,
#main #total-main div.fourths-middle,
#main #total-main div.left,
#main #total-main div.thirds-left,
#main #total-main div.thirds-middle {
  margin-bottom: 40px;
}

#vrtx-frontpage #main {
  .vrtx-frontpage-box.purple-box {
    background: @exhibitionInfoBg;

    &:first-child {
      padding-left: 40px;
    }
    &:last-child {
      padding-right: 40px;
    }

    > *:first-child { padding-top: 40px;    }
    > *:last-child  { padding-bottom: 40px; }
  }
}

.full-width-picture-bg + .row-all-colored {
  margin-top: -80px;
}

#main .grid-container {
  margin-bottom: 120px;
}

#vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes) + .row-all-colored:not(.row-all-hidden-boxes) {
  margin-top: 0;
}

#vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes) {
  &.grid-color-grey,
  &.grid-color-yellow,
  &.grid-color-black {
    + .row-all-colored:not(.row-all-hidden-boxes) {
      &.grid-color-grey,
      &.grid-color-yellow,
      &.grid-color-black {
        margin-top: -120px;
      }
    }
  }
}

#main .row-all-colored:not(.grid-color-yellow):not(.grid-color-green):not(.grid-color-black):not(.grid-color-grey) {
  background: transparent;
}

#vrtx-frontpage #vrtx-main-content .row-all-colored:first-child {
  margin-bottom: 170px;
}

#vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child {
  margin-bottom: 95px;

  &.grid-color-grey,
  &.grid-color-yellow,
  &.grid-color-black {
    margin-bottom: -25px;
  }
}

.vrtx-frontpage-box.plain-text {
  margin-top: -40px;
}

.vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left,
.vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right {
  &.transparent-box {
    .vrtx-box-content {
      padding-top: 60px;
      padding-bottom: 80px;
    }
  }
}

#footer-wrapper-back-to-uio {
  background: @footerBg;
}

@media @desktopSmallBreakpoint {
  /*
  .grid-container.fullwidth-video .text-box,
  .grid-container.fullwidth-image .vrtx-box-content {
    background-color: @globalBg;
  }
  */

  #responsive-submenu #toggle-responsive-submenu,
  #responsive-submenu.expanded #toggle-responsive-submenu {
    background-color: @tilesGlobalMenuBg;
    border-width: 0;
  }
  #responsive-submenu .vrtx-subfolder-menu,
  #responsive-submenu>ul {
    border-top: 1px solid #555;
    background-color: @tilesGlobalMenuBg;
  }
  #head-wrapper {
    min-height: 130px;
  }
}

iframe.toyen-bee-iframe {
  overflow: hidden;
  max-width: 100%;
}

@media @responsiveBreakpoint {
  #logo {
    margin-top: 0;
    max-width: 80%;
    background-size: 100% auto;
  }

  iframe.toyen-bee-iframe {
    height: 1300px !important;
  }

  #main #right-main .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left,
  #main #right-main .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right,
  #main #total-main .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left,
  #main #total-main .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right {
    &.transparent-box {
      padding-bottom: 0;
    }
  }

  #vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child.grid-color-grey, #vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child.grid-color-yellow, #vrtx-frontpage #vrtx-main-content .row-all-colored:not(.row-all-hidden-boxes):last-child.grid-color-black {
    margin-bottom: 0;
  }

  #vrtx-frontpage #main .grid-container.row-last-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive) + .row-first-colored {
    margin-top: 0;
  }
  #vrtx-frontpage #main .grid-container.row-last-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes):not(.row-all-hidden-boxes-responsive) {
    &.grid-color-grey,
    &.grid-color-yellow,
    &.grid-color-black {
      + .row-first-colored:not(.row-all-hidden-boxes) {
        &.grid-color-grey,
        &.grid-color-yellow,
        &.grid-color-black {
          margin-top: -80px;
        }
      }
    }
  }
  #vrtx-frontpage #vrtx-main-content .row-all-colored:first-child {
    margin-bottom: 80px;
  }

  #vrtx-frontpage #main #vrtx-main-content .grid-container {
    .vrtx-frontpage-box.purple-box {
      background: @exhibitionInfoBg;

      + .vrtx-frontpage-box.purple-box {
        margin-top: -80px;
      }

      &:first-child {
        padding-left: 15px;
      }
      &:last-child {
        padding-right: 15px;
      }

      > *:first-child { padding-top: 20px;    }
      > *:last-child  { padding-bottom: 20px; }
    }
  }

  #main #right-main .frontpage-img-carousel .row-all-colored .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left .vrtx-frontpage-box-picture,
  #main #right-main .frontpage-img-carousel .row-all-colored .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right .vrtx-frontpage-box-picture,
  #main #total-main .frontpage-img-carousel .row-all-colored .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-left .vrtx-frontpage-box-picture,
  #main #total-main .frontpage-img-carousel .row-all-colored .vrtx-frontpage-box.vrtx-frontpage-box-picture.img-special-right .vrtx-frontpage-box-picture {
    margin-top: 0;
  }
}

@media print {
  html,
  #left-menu-same-level-folders,
  #main .grid-container:not(.row-all-colored),
  #main .row-all-colored:not(.grid-color-yellow):not(.grid-color-black):not(.grid-color-grey) {
    background: transparent;
  }
}
